import { faEnvelopeOpenText, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';
const Footer = () => {
    return (
        <footer>
            <div className="bg-white footer-top">
                <div className="container">
                    <div className="row margin-four">
                        {/* <div className="col-md-4 col-sm-4 text-center">
                            <FontAwesomeIcon className="small-icon" icon={faMobileAlt} />
                            <h6 class="black-text margin-two no-margin-bottom">(347) 836-5020 </h6>
                        </div> */}
                        <div className="col-md-6 col-sm-4 text-center">
                            <FontAwesomeIcon className="small-icon" icon={faMapMarkerAlt} />
                            <h6 class="black-text margin-two no-margin-bottom">Shantinagar, Dhaka-1217</h6>
                        </div>
                        <div className="col-md-6 col-sm-4 text-center">
                        <FontAwesomeIcon className="small-icon" icon={faEnvelopeOpenText} />
                            <h6 class="margin-two no-margin-bottom"><a href="mailto:info@avidgroup.org" class="black-text">info@avidtechsl.com</a></h6>
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-dark-gray footer-bottom">
                    <div className="container">
                        <div className="row margin-three">
                            <div class="col-xs-12 copyright text-center letter-spacing-1 xs-text-center xs-margin-bottom-one">
                               Copyright &#169; AVID TECH SOLUTIONS LTD  {(new Date().getFullYear())}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;