import React from 'react';
import './HeaderMain.css';
const HeaderMain = () => {



    let header = `<h1 className="main-title white-text">SECURE. PRESERVE. MAINTAIN</h1>`;
    return (
        <div id="Home" >
            <div class="tilte" >
                <div className="opacity-light bg">
                    <div className="header">
                    {/* <Typewriter
                            options={{
                                strings: header,
                                cursor: null,
                                autoStart: true,
                                loop: true,
                            }}
                        /> */}
                        {/* <h1 className="main-title white-text">Avid Group Ltd</h1> */}
                        {/* <h6 className="white-text">YOU CAN TRUST AVID GROUP TO HANDLE THE TOUGH STUFF. WE'VE GOT YOU COVERED</h6> */}
                    </div>
                </div>

            </div>

        </div>
    );
};

export default HeaderMain;