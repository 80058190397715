import React from 'react';
import aboutImg from '../../Image/about.jpg';
import why from '../../Image/WHY.jpg';
import './About.css';
const About = () => {
    return (
        <div id="About">
            <br />
            <div class="point container">
                <h2>Avid Tech Solutions Ltd</h2>
                <div class="separator-line bg-black margin-two no-margin-bottom"></div>
                <div class="row first-component">
                    <div className="col-md-6 one">
                        
                            <h1 class="titleTwo">WHO IS AVID SOLUTIONS LTD?</h1>
                            <div class="separator-line bg-black no-margin-lr"></div>
                            <p class="text-med">
                            Avid Tech Solutions is a business process outsourcing firm specializing in offshore Real Estate management and other IT-enabled services. We have successfully run multi-state operations and worked with all of the largest national clients in the United States to be an essential element of the property preservation industry. The company was founded by a group of professionals with extensive experience and exposure to information technology. Young qualified business graduates and qualified engineers from renowned universities around the world are involved here. Aside from BPO, we also offer website design, software development, mobile app development, information systems security solutions, and network security.
                            </p>
                        

                    </div>
                    <div className="col-md-6">
                        
                            <img className="imageOne" src={aboutImg} alt="" />
                        

                    </div>
                </div>

                <div class="row first-component">
                    <div className="col-md-6 one">
                        
                            <img className="imageOne" src={why} alt="" />
                        
                    </div>
                    <div className="col-md-6">
                        
                            <h1 class='titleTwo'>WHY IS AVID TECH SOLUTIONS LTD DIFFERENT?</h1>
                            <div class="separator-line bg-black no-margin-lr"></div>
                            <p class="text-med-two">
                                With honesty and integrity at the forefront, we pride ourselves at ensuring customers are given the utmost priority. We therefore put effort in ensuring there is open communication between you, the customer, and us by establishing strong interpersonal relationships right from the start. In addition, to ensure we achieve the ultimate goal of preserving and securing your assets, we carry out criminal background checks on our staffs before hiring them. This guarantees that only the best and true professionals handle your property. We also mould the staffs into our own liking to ensure they understand that customers are our number one priority. We further provide them (our staffs) with monthly training sessions to instill any new industry regulations and requirements.
                            </p>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;