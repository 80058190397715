import emailjs from 'emailjs-com';
import React from 'react';
import './Contact.css';
const Contact = () => {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_r0plfhi', 'template_u09h6hd', e.target, 'user_kftkAVN4w3dLOjj83aAtF')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <div id="Contact" className="xs-onepage-section bg-gray margin-top-section">
            <div className="container middle">
                <div class="row" >
                    <div class="col-md-5 col-sm-6">
                        <h1 className="headingThree">CONTACT US</h1>
                        <p className="text-med">Drop us an email if you have any questions, We always try to get back to you within 24 hours.</p>
                        <p className="text-med">
                            <strong>Registered Address</strong>
                            <br />
                            Address: 145, Eastern Plus Commercial Complex, 7th floor,Suite-19, Shantinagar, Dhaka-1217.
                            <br />
                            <strong>Mailing Address</strong>
                            <br />
                            Address: Holding-9, Road-3, Block-F, Section-2, Mirpur, Dhaka-1216.
                            <br />
                            <b>Email:</b> info@avidtechsl.com
                        </p>
                    </div>
                    <div class="col-md-5 col-sm-6 no-border-round">
                        <div className="emailLoader">
                            <div className="uil-ring-css">
                                <div></div>
                            </div>
                        </div>

                        <form onSubmit={sendEmail} id="free30daytrialform">
                            <div id="success-free30daytrial" class="no-margin-lr" style={{ display: 'none' }}></div>
                            <div class="form-group no-margin-bottom">
                                <input type="text" class="text-edit" id="name" name="name" minlength="3" placeholder="Your Name" required="" />
                            </div>
                            <div class="form-group no-margin-bottom">
                                <input type="text" class="text-edit" id="email" name="email" placeholder="Your Email" />
                            </div>
                            <div class="form-group no-margin-bottom">
                                <input type="text" class="text-edit" id="phone" name="phone" placeholder="Your Phone" />
                            </div>
                            <div class="form-group no-margin-bottom">
                                <textarea class="text-edit" placeholder="Your Message" minlength="3" name="message" required=""></textarea>
                            </div>

                            <button type="submit" name="free30daytrial-button" id="free30daytrial-button" class="btn-success btn btn-medium  button button-3d btn-round no-margin">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;